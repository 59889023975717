<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  Finance
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                Finance
              </div>
              <div class="col-6 text-right">

              </div>
              <v-row>
                <v-col cols="4">
                  <v-card flat class="border">
                    <v-card-title class="justify-center">
                      Total pending
                    </v-card-title>
                    <v-card-text class="justify-center text-center">
                      <div class="text-center headline ">
                        Rs. {{ stats.total_pending || '0' }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <h4>Pending payments</h4>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Fee</th>
                      <th>Amount</th>
                      <th>Due date</th>
                    </tr>
                    </thead>
                    <tr v-for="(pending, index) of stats.pending_payments" :key="index">
                      <td>{{ pending.title }}</td>
                      <td>Rs. {{ pending.amount }}</td>
                      <td>{{ pending.due_date_text }}</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";

const studentFee = new StudentFeeService();


export default {
  name: "Student-Courses",
  data() {
    return {
      stats: {},
    }
  }, computed: {
    current_user() {
      return this.$store.getters.currentUser;
    }
  }, mounted() {
    if (this.current_user.id)
      this.getFinancialStat()
  }, methods: {
    getFinancialStat() {
      studentFee.getStudentPending(this.current_user.id).then(response => {
        this.stats = response.data.stats;
      })
    }
  }

}
</script>

<style scoped>

</style>
